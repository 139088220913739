










import Vue from 'vue'
import PositionService from '@/services/position'
import FilterField from './components/FilterField.vue'
import PositionTable from './components/PositionTable.vue'
import { sumBy as _sumBy, cloneDeep as _cloneDeep } from 'lodash'

export default Vue.extend({
  components: {
    FilterField,
    PositionTable
  },

  data(): {
    loading: boolean
    tableData: Record<string, any>[]
    filter?: Record<string, any>
  } {
    return {
      loading: false,
      tableData: [],
      filter: undefined
    }
  },

  computed: {
    totalNotional(): number {
      return _sumBy(this.tableData, 'qty_quote_ccy_remaining')
    },

    filteredValue(): any[] {
      let val = _cloneDeep(this.tableData)
      if (!this.filter) return val

      // if (this.filter?.entry_price !== '') {
      //   val = val.filter((item) => Number(item.entry_price) === Number(this.filter?.entry_pice))
      // }

      if (this.filter.exchange !== '') {
        val = val.filter((item) => item.exchange === this.filter?.exchange)
      }

      if (this.filter.side !== '') {
        val = val.filter((item) => item.side === this.filter?.side)
      }

      if (this.filter.symbol !== '') {
        val = val.filter((item) => item.symbol.includes(this.filter?.symbol.toUpperCase()))
      }

      if (this.filter.status !== '') {
        val = val.filter((item) => item.status === this.filter?.status)
      }

      return val
    }
  },

  methods: {
    async getPosition() {
      this.loading = true

      try {
        const res = await PositionService.getPosition()
        this.tableData = res.data.custom?.data || []
      } finally {
        this.loading = false
      }
    },

    onFilter(emitData: any) {
      this.filter = emitData
    }
  },

  created() {
    this.getPosition()
  }
})
